import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
} from "@mui/material";
import { useState } from "react";

//redux
import { connect } from "react-redux";
import { createManager, editManager } from "../../stores/managers/actions";
import { showAlert } from "../../helpers";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
    .object({
        firstName: yup.string().required("Firstname is required"),
        lastName: yup.string().required("Lastname is required"),
        email: yup.string().email().required("Email is required"),
    })
    .required();

//helpers

function AddSystemUserDialog({
    open,
    onClose = () => {},
    createManager = () => {},
    editManager = () => {},
    manager,
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const { firstName, lastName, email, username } = data;
            if (manager?._id) {
                await editManager(manager._id, {
                    name: firstName + " " + lastName,
                    email,
                    username: email,
                });
            } else {
                let randomPassword = (Math.random() + 1)
                    .toString(36)
                    .substring(2);
                await createManager({
                    firstName,
                    lastName,
                    email,
                    password: randomPassword,
                    passwordConfirm: randomPassword,
                });
            }
            showAlert({
                title: "Success!",
                message: `Manager ${
                    manager?._id ? "updated" : "created"
                } successfully!`,
                type: "success",
            });
            onClose();
        } catch (err) {
            let message = "Something went wrong!";
            if (err?.response?.data?.message) {
                message = err?.response?.data?.message;
            }
            showAlert({ title: "Error!", message, type: "danger" });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Add Manager</DialogTitle>
                <DialogContent sx={{ width: "100%" }}>
                    <Grid
                        container
                        spacing={2}
                        sx={{ width: "100%", marginBottom: 2 }}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{ width: "100%" }}
                                {...register("firstName")}
                                label="First Name"
                                sx={{
                                    width: {
                                        md: "500px",
                                    },
                                }}
                            />
                            {errors.firstName && (
                                <p style={{ color: "#fa6e6e", fontSize: "14px" }}>
                                    {errors.firstName?.message}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ width: "100%", marginBottom: 2 }}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{ width: "100%" }}
                                {...register("lastName")}
                                label="Last Name"
                                sx={{
                                    width: {
                                        md: "500px",
                                    },
                                }}
                            />
                            {errors.lastName && (
                                <p style={{ color: "#fa6e6e", fontSize: "14px" }}>
                                    {errors.lastName?.message}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ width: "100%", marginBottom: 2 }}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                sx={{ width: "100%" }}
                                {...register("email")}
                                label="Email"
                                sx={{
                                    width: {
                                        md: "500px",
                                    },
                                }}
                                type="email"
                            />
                            {errors.email && (
                                <p style={{ color: "#fa6e6e", fontSize: "14px" }}>
                                    {errors.email?.message}
                                </p>
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        style={{ background: "#4ac4f3" }}
                        type="submit"
                        variant="contained"
                        disabled={loading}
                    >
                        {manager?._id ? "Update" : "Submit"}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    createManager,
    editManager,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSystemUserDialog);
