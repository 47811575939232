import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// redux setup
import { PersistGate } from "redux-persist/integration/react";
import Store from "./stores";
import { Provider } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import { ContextProvider } from "./context";

ReactDOM.render(
  <Provider store={Store().store}>
    <PersistGate loading={null} persistor={Store().persistor}>
      <React.StrictMode>
        <ContextProvider>
          <App />
        </ContextProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
