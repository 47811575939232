import { Card, CardContent, Typography } from "@mui/material";
// import { useEffect, useRef } from "react"
// import Chart from "chart.js/auto"

function StatCard(props) {
    const {
        title,
        tableData = [],
        onClick = () => {},
        active,
    } = props;

    return (
        <div style={{ cursor: "pointer" }}>
            <Typography variant="h6">{title}</Typography>
            <Card
                onClick={onClick}
                sx={{
                    borderRadius: "8px",
                    boxShadow: active
                        ? "0px 0px 20px rgba(0, 0, 0, 0.25)"
                        : "0px 0px 5px rgba(0, 0, 0, 0.25)",
                    position: "relative",
                    height: "200px",
                    transition: "box-shadow 0.5s",
                    marginTop: "10px",
                    background: active ? "#fffff3" : "#ffffff",
                }}
            >
                <CardContent>
                    {tableData.map((row, i) => (
                        <div
                            key={i}
                            style={{
                                padding: "0px 0px",
                                display: "flex",
                                margin: `${i === 0 ? 0 : 8}px 0px`,
                            }}
                        >
                            <span style={{ flex: 1 }}>{row.title}</span>
                            <span
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                                {row.value}
                            </span>
                        </div>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
}

export default StatCard;
