import { Card } from "@mui/material";
import { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export default function JobChart({ jobs, data: chartData }) {
    console.log(chartData);
    const [chartValues, setChartValues] = useState([20, 0, 4]);
    const data = {
        labels: ["Open", "Closed", "On Hold"],
        datasets: [
            {
                label: "",
                data: [
                    chartData?.openInterviews?.length || 0,
                    chartData?.closedInterviews?.length || 0,
                    chartData?.onHoldInterviews?.length || 0,
                ],
                backgroundColor: [
                    "rgb(255, 206, 86)",
                    "rgb(54, 162, 235)",
                    "rgb(255, 99, 132)",
                ],
                borderWidth: 1,
            },
        ],
    };
    useEffect(() => {
        let pendingCount = 0,
            interviewedCount = 0,
            cancelledCount = 0;
        jobs.map((job) => {
            job.candidates.map((candidate) => {
                if (candidate.status === "invited") {
                    pendingCount++;
                } else if (candidate.status === "completed") {
                    interviewedCount++;
                } else if (candidate.status === "cancelled") {
                    cancelledCount++;
                }
            });
        });
        setChartValues([pendingCount, interviewedCount, cancelledCount]);
    }, [jobs]);

    console.log(chartValues);
    return (
        <Card
            sx={{
                height: "100%",
            }}
        >
            <Pie data={data} />
        </Card>
    );
}
