import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//redux
import { connect } from "react-redux";
import { register } from "../stores/auth/actions";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

//helpers
import { showAlert } from "../helpers/";

import "../custom-styles/signup.css";
import { TextField } from "@mui/material";

const schema = yup
    .object({
        firstName: yup.string().required("Firstname is required"),
        lastName: yup.string().required("Lastname is required"),
        jobTitle: yup.string().required("Job Title is required"),
        companyName: yup.string().required("Company Name is required"),
        country: yup.string().required("Country is required"),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        address: yup.string().required("Address is required"),
        zipCode: yup.string().required("Zip Code is required"),
        website: yup.string().required("Website is required"),
        phone: yup.string().required("Phone is required"),
        email: yup.string().email().required("Email is required"),
        password: yup.string().required("Password is required"),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Please confirm your password"),
    })
    .required();

function SignUp(props) {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        const { confirmPassword, acknowledge, ...rest } = data;
        setAcceptTermsError("");

        if (!acceptTerms) {
            setAcceptTermsError(
                "Please accept the terms and conditions before proceeding"
            );
            return;
        }

        try {
            setBtnLoading(true);
            const resp = await props.register(rest);
            reset();
            showAlert({
                title: "Success!",
                message: resp.message,
                type: "success",
            });
            navigate("/");
        } catch (err) {
            let message = "Something went wrong!";
            if (err?.response?.data?.message) {
                message = err?.response?.data?.message;
            }
            showAlert({ title: "Error!", message, type: "danger" });
        } finally {
            setBtnLoading(false);
        }
    };
    const [btnLoading, setBtnLoading] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptTermsError, setAcceptTermsError] = useState("");

    // console.log(form);

    return (
        <div className="signup-container">
            <div className="signup-left">
                <div className="signup-left-content">
                    <h3>
                        Transforming hiring process through <br />
                        <b>Video Based Interviews</b>
                    </h3>
                    <p>
                        Easy, efficient & better pre screening for hiring best
                        talent
                    </p>
                    <p>
                        Enterprise-grade security and compliance protects
                        critical data
                    </p>
                    <div className="left-footer">
                        <p>@ {new Date().getFullYear()} Planethiring </p>
                        <div>
                            <a
                                href="https://planethiring.com/privacy-policy"
                                target="_blank"
                                rel="noopenner noreferrer"
                            >
                                Privacy
                            </a>
                            <a
                                href="https://planethiring.com/terms-and-conditions"
                                target="_blank"
                                rel="noopenner noreferrer"
                            >
                                Legal
                            </a>
                            <a
                                href="https://planethiring.com/#contact"
                                target="_blank"
                                rel="noopenner noreferrer"
                            >
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="signup-right">
                <div className="abs-right">
                    Already have an account? <Link to="/">Sign In</Link>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="signup-form">
                    <h3>Sign Up</h3>
                    <div className="inputs-group">
                        <div className="input">
                            {/* <input {...register("firstName")} type="text" /> */}
                            <TextField
                                variant="standard"
                                {...register("firstName")}
                                label="First Name"
                            />
                            {errors.firstName && (
                                <p className="input-error">
                                    {errors.firstName?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("lastName")}
                                label="Last Name"
                            />
                            {errors.lastName && (
                                <p className="input-error">
                                    {errors.lastName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("jobTitle")}
                                type="text"
                                label="Job Title"
                            />
                            {errors.jobTitle && (
                                <p className="input-error">
                                    {errors.jobTitle?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("companyName")}
                                type="text"
                                label="Company Name"
                            />
                            {errors.companyName && (
                                <p className="input-error">
                                    {errors.companyName?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("country")}
                                type="text"
                                label="Country"
                            />
                            {errors.country && (
                                <p className="input-error">
                                    {errors.country?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("city")}
                                type="text"
                                label="City"
                            />
                            {errors.city && (
                                <p className="input-error">
                                    {errors.city?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("state")}
                                type="text"
                                label="State"
                            />
                            {errors.state && (
                                <p className="input-error">
                                    {errors.state?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("address")}
                                type="text"
                                label="Address"
                            />
                            {errors.address && (
                                <p className="input-error">
                                    {errors.address?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("zipCode")}
                                type="text"
                                label="Zip Code"
                            />
                            {errors.zipCode && (
                                <p className="input-error">
                                    {errors.zipCode?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("website")}
                                type="text"
                                label="Website"
                            />
                            {errors.website && (
                                <p className="input-error">
                                    {errors.website?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("phone")}
                                type="text"
                                label="Phone"
                            />
                            {errors.phone && (
                                <p className="input-error">
                                    {errors.phone?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("email")}
                                type="email"
                                label="email"
                            />
                            {errors.email && (
                                <p className="input-error">
                                    {errors.email?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("password")}
                                type="password"
                                label="Password"
                            />
                            {errors.password && (
                                <p className="input-error">
                                    {errors.password?.message}
                                </p>
                            )}
                        </div>
                        <div className="input">
                            <TextField
                                variant="standard"
                                {...register("confirmPassword")}
                                type="password"
                                label="Confirm Password"
                            />
                            {errors.confirmPassword && (
                                <p className="input-error">
                                    {errors.confirmPassword?.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="inputs-group">
                        <div className="input">
                            <label for="acknowledge" className="ack-label">
                                <input
                                    id="acknowledge"
                                    value={acceptTerms}
                                    onChange={(e) => {
                                        setAcceptTerms(e.target.checked);
                                        setAcceptTermsError("");
                                    }}
                                    type="checkbox"
                                />
                                <span>
                                    I acknowledge that I have read, understood
                                    and agreed to all{" "}
                                    <a
                                        href="https://planethiring.com/terms-and-conditions"
                                        rel="noreferrer"
                                        target={"_blank"}
                                    >
                                        terms
                                    </a>{" "}
                                    ,{" "}
                                    <a
                                        href="https://planethiring.com/terms-and-conditions"
                                        rel="noreferrer"
                                        target={"_blank"}
                                    >
                                        privacy
                                    </a>{" "}
                                    and license agreement
                                </span>
                            </label>
                            {acceptTermsError && (
                                <p className="input-error">
                                    {acceptTermsError}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="btn-cont">
                        <button type="submit" disabled={btnLoading}>
                            Sign Up
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    token: state.auth.token,
});
const mapDispatchToProps = {
    register,
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
